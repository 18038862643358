@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

html {
  position: relative;
  min-height: 100%; }

body {
  font-family: 'Open Sans',sans-serif;
  font-weight: 300; }

button {
  outline: none; }

.container {
  max-width: 980px; }

.text-center .img-responsive {
  display: inline-block; }

.spacer-10px {
  margin-top: 10px; }

.spacer-15px {
  margin-top: 15px; }

.spacer-20px {
  margin-top: 20px; }

.noPadding {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.noMargin {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.itmodel {
  margin-top: 20px; }

.mfp-bg {
  background: #FFF;
  opacity: 1; }

.mfp-image-holder .mfp-close {
  color: #191919; }

.mfp-arrow-left:before {
  border: none; }

.mfp-arrow-left:after {
  border-right-color: #191919; }

.mfp-arrow-right:before {
  border: none; }

.mfp-arrow-right:after {
  border-left-color: #191919; }

.mfp-counter {
  color: #191919; }

/* five columns */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-15 {
  width: 20%;
  float: left; }

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left; } }

#page-home.simple-header.navbar,
#page-home .navbar,
#page-sobre.simple-header.navbar,
#page-sobre .navbar,
#page-modelos.simple-header.navbar,
#page-modelos .navbar,
#page-cidades.simple-header.navbar,
#page-cidades .navbar {
  border-radius: 0;
  min-height: auto;
  background-color: #191919;
  border: 0;
  margin-bottom: 0; }
  #page-home.simple-header.navbar .logo,
  #page-home .navbar .logo,
  #page-sobre.simple-header.navbar .logo,
  #page-sobre .navbar .logo,
  #page-modelos.simple-header.navbar .logo,
  #page-modelos .navbar .logo,
  #page-cidades.simple-header.navbar .logo,
  #page-cidades .navbar .logo {
    margin-top: 15px; }
  #page-home.simple-header.navbar ul.menu,
  #page-home .navbar ul.menu,
  #page-sobre.simple-header.navbar ul.menu,
  #page-sobre .navbar ul.menu,
  #page-modelos.simple-header.navbar ul.menu,
  #page-modelos .navbar ul.menu,
  #page-cidades.simple-header.navbar ul.menu,
  #page-cidades .navbar ul.menu {
    margin: 0; }
    #page-home.simple-header.navbar ul.menu.signup,
    #page-home .navbar ul.menu.signup,
    #page-sobre.simple-header.navbar ul.menu.signup,
    #page-sobre .navbar ul.menu.signup,
    #page-modelos.simple-header.navbar ul.menu.signup,
    #page-modelos .navbar ul.menu.signup,
    #page-cidades.simple-header.navbar ul.menu.signup,
    #page-cidades .navbar ul.menu.signup {
      margin-bottom: 3px; }
      #page-home.simple-header.navbar ul.menu.signup li,
      #page-home .navbar ul.menu.signup li,
      #page-sobre.simple-header.navbar ul.menu.signup li,
      #page-sobre .navbar ul.menu.signup li,
      #page-modelos.simple-header.navbar ul.menu.signup li,
      #page-modelos .navbar ul.menu.signup li,
      #page-cidades.simple-header.navbar ul.menu.signup li,
      #page-cidades .navbar ul.menu.signup li {
        color: #939598; }
        #page-home.simple-header.navbar ul.menu.signup li a,
        #page-home .navbar ul.menu.signup li a,
        #page-sobre.simple-header.navbar ul.menu.signup li a,
        #page-sobre .navbar ul.menu.signup li a,
        #page-modelos.simple-header.navbar ul.menu.signup li a,
        #page-modelos .navbar ul.menu.signup li a,
        #page-cidades.simple-header.navbar ul.menu.signup li a,
        #page-cidades .navbar ul.menu.signup li a {
          color: #ed145b;
          padding-left: 3px;
          padding-right: 0;
          text-decoration: underline; }
    #page-home.simple-header.navbar ul.menu.logged,
    #page-home .navbar ul.menu.logged,
    #page-sobre.simple-header.navbar ul.menu.logged,
    #page-sobre .navbar ul.menu.logged,
    #page-modelos.simple-header.navbar ul.menu.logged,
    #page-modelos .navbar ul.menu.logged,
    #page-cidades.simple-header.navbar ul.menu.logged,
    #page-cidades .navbar ul.menu.logged {
      margin-top: 12px; }
      #page-home.simple-header.navbar ul.menu.logged li a,
      #page-home .navbar ul.menu.logged li a,
      #page-sobre.simple-header.navbar ul.menu.logged li a,
      #page-sobre .navbar ul.menu.logged li a,
      #page-modelos.simple-header.navbar ul.menu.logged li a,
      #page-modelos .navbar ul.menu.logged li a,
      #page-cidades.simple-header.navbar ul.menu.logged li a,
      #page-cidades .navbar ul.menu.logged li a {
        padding: 0;
        letter-spacing: inherit; }
      #page-home.simple-header.navbar ul.menu.logged li .meuPerfil img,
      #page-home .navbar ul.menu.logged li .meuPerfil img,
      #page-sobre.simple-header.navbar ul.menu.logged li .meuPerfil img,
      #page-sobre .navbar ul.menu.logged li .meuPerfil img,
      #page-modelos.simple-header.navbar ul.menu.logged li .meuPerfil img,
      #page-modelos .navbar ul.menu.logged li .meuPerfil img,
      #page-cidades.simple-header.navbar ul.menu.logged li .meuPerfil img,
      #page-cidades .navbar ul.menu.logged li .meuPerfil img {
        border-radius: 50%;
        margin-right: 5px; }
      #page-home.simple-header.navbar ul.menu.logged li ul.dropdown-menu li,
      #page-home .navbar ul.menu.logged li ul.dropdown-menu li,
      #page-sobre.simple-header.navbar ul.menu.logged li ul.dropdown-menu li,
      #page-sobre .navbar ul.menu.logged li ul.dropdown-menu li,
      #page-modelos.simple-header.navbar ul.menu.logged li ul.dropdown-menu li,
      #page-modelos .navbar ul.menu.logged li ul.dropdown-menu li,
      #page-cidades.simple-header.navbar ul.menu.logged li ul.dropdown-menu li,
      #page-cidades .navbar ul.menu.logged li ul.dropdown-menu li {
        text-align: center;
        padding: 10px 0; }
        #page-home.simple-header.navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-home .navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-sobre.simple-header.navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-sobre .navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-modelos.simple-header.navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-modelos .navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-cidades.simple-header.navbar ul.menu.logged li ul.dropdown-menu li a:hover,
        #page-cidades .navbar ul.menu.logged li ul.dropdown-menu li a:hover {
          background-color: transparent;
          color: #58595b; }
    #page-home.simple-header.navbar ul.menu a,
    #page-home .navbar ul.menu a,
    #page-sobre.simple-header.navbar ul.menu a,
    #page-sobre .navbar ul.menu a,
    #page-modelos.simple-header.navbar ul.menu a,
    #page-modelos .navbar ul.menu a,
    #page-cidades.simple-header.navbar ul.menu a,
    #page-cidades .navbar ul.menu a {
      color: #939598;
      text-decoration: none; }
    #page-home.simple-header.navbar ul.menu li,
    #page-home .navbar ul.menu li,
    #page-sobre.simple-header.navbar ul.menu li,
    #page-sobre .navbar ul.menu li,
    #page-modelos.simple-header.navbar ul.menu li,
    #page-modelos .navbar ul.menu li,
    #page-cidades.simple-header.navbar ul.menu li,
    #page-cidades .navbar ul.menu li {
      padding: 0;
      position: relative; }
      #page-home.simple-header.navbar ul.menu li a,
      #page-home .navbar ul.menu li a,
      #page-sobre.simple-header.navbar ul.menu li a,
      #page-sobre .navbar ul.menu li a,
      #page-modelos.simple-header.navbar ul.menu li a,
      #page-modelos .navbar ul.menu li a,
      #page-cidades.simple-header.navbar ul.menu li a,
      #page-cidades .navbar ul.menu li a {
        padding: 20px 15px;
        color: #939598;
        letter-spacing: 1px;
        display: inline-block;
        text-decoration: none;
        font-size: 12px; }
        #page-home.simple-header.navbar ul.menu li a:hover,
        #page-home .navbar ul.menu li a:hover,
        #page-sobre.simple-header.navbar ul.menu li a:hover,
        #page-sobre .navbar ul.menu li a:hover,
        #page-modelos.simple-header.navbar ul.menu li a:hover,
        #page-modelos .navbar ul.menu li a:hover,
        #page-cidades.simple-header.navbar ul.menu li a:hover,
        #page-cidades .navbar ul.menu li a:hover {
          color: #fff; }
        #page-home.simple-header.navbar ul.menu li a.recovery,
        #page-home .navbar ul.menu li a.recovery,
        #page-sobre.simple-header.navbar ul.menu li a.recovery,
        #page-sobre .navbar ul.menu li a.recovery,
        #page-modelos.simple-header.navbar ul.menu li a.recovery,
        #page-modelos .navbar ul.menu li a.recovery,
        #page-cidades.simple-header.navbar ul.menu li a.recovery,
        #page-cidades .navbar ul.menu li a.recovery {
          padding: 0;
          font-size: 11px;
          color: #939598;
          letter-spacing: 0;
          font-weight: 400; }
        #page-home.simple-header.navbar ul.menu li a.button-box-login,
        #page-home .navbar ul.menu li a.button-box-login,
        #page-sobre.simple-header.navbar ul.menu li a.button-box-login,
        #page-sobre .navbar ul.menu li a.button-box-login,
        #page-modelos.simple-header.navbar ul.menu li a.button-box-login,
        #page-modelos .navbar ul.menu li a.button-box-login,
        #page-cidades.simple-header.navbar ul.menu li a.button-box-login,
        #page-cidades .navbar ul.menu li a.button-box-login {
          font-size: 14px;
          color: #fff;
          background-color: #222;
          padding-left: 30px;
          padding-right: 30px; }
          #page-home.simple-header.navbar ul.menu li a.button-box-login .box,
          #page-home .navbar ul.menu li a.button-box-login .box,
          #page-sobre.simple-header.navbar ul.menu li a.button-box-login .box,
          #page-sobre .navbar ul.menu li a.button-box-login .box,
          #page-modelos.simple-header.navbar ul.menu li a.button-box-login .box,
          #page-modelos .navbar ul.menu li a.button-box-login .box,
          #page-cidades.simple-header.navbar ul.menu li a.button-box-login .box,
          #page-cidades .navbar ul.menu li a.button-box-login .box {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #262626;
            z-index: 10; }
            #page-home.simple-header.navbar ul.menu li a.button-box-login .box .circle,
            #page-home .navbar ul.menu li a.button-box-login .box .circle,
            #page-sobre.simple-header.navbar ul.menu li a.button-box-login .box .circle,
            #page-sobre .navbar ul.menu li a.button-box-login .box .circle,
            #page-modelos.simple-header.navbar ul.menu li a.button-box-login .box .circle,
            #page-modelos .navbar ul.menu li a.button-box-login .box .circle,
            #page-cidades.simple-header.navbar ul.menu li a.button-box-login .box .circle,
            #page-cidades .navbar ul.menu li a.button-box-login .box .circle {
              border: 1px solid #414042;
              border-radius: 100%;
              width: 40px;
              height: 40px;
              display: block;
              text-align: center;
              margin-top: 8px;
              margin-left: 32px;
              padding-top: 10px;
              font-size: 12px;
              color: #939598; }
        #page-home.simple-header.navbar ul.menu li a.active,
        #page-home .navbar ul.menu li a.active,
        #page-sobre.simple-header.navbar ul.menu li a.active,
        #page-sobre .navbar ul.menu li a.active,
        #page-modelos.simple-header.navbar ul.menu li a.active,
        #page-modelos .navbar ul.menu li a.active,
        #page-cidades.simple-header.navbar ul.menu li a.active,
        #page-cidades .navbar ul.menu li a.active {
          background-color: rgba(38, 38, 38, 0.9); }
      #page-home.simple-header.navbar ul.menu li ul.box-login,
      #page-home.simple-header.navbar ul.menu li ul.box-menu,
      #page-home .navbar ul.menu li ul.box-login,
      #page-home .navbar ul.menu li ul.box-menu,
      #page-sobre.simple-header.navbar ul.menu li ul.box-login,
      #page-sobre.simple-header.navbar ul.menu li ul.box-menu,
      #page-sobre .navbar ul.menu li ul.box-login,
      #page-sobre .navbar ul.menu li ul.box-menu,
      #page-modelos.simple-header.navbar ul.menu li ul.box-login,
      #page-modelos.simple-header.navbar ul.menu li ul.box-menu,
      #page-modelos .navbar ul.menu li ul.box-login,
      #page-modelos .navbar ul.menu li ul.box-menu,
      #page-cidades.simple-header.navbar ul.menu li ul.box-login,
      #page-cidades.simple-header.navbar ul.menu li ul.box-menu,
      #page-cidades .navbar ul.menu li ul.box-login,
      #page-cidades .navbar ul.menu li ul.box-menu {
        outline: none;
        display: none;
        width: 450px;
        margin: 0;
        padding: 15px;
        list-style: none;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: rgba(38, 38, 38, 0.9); }
        #page-home.simple-header.navbar ul.menu li ul.box-login li .checkbox,
        #page-home.simple-header.navbar ul.menu li ul.box-menu li .checkbox,
        #page-home .navbar ul.menu li ul.box-login li .checkbox,
        #page-home .navbar ul.menu li ul.box-menu li .checkbox,
        #page-sobre.simple-header.navbar ul.menu li ul.box-login li .checkbox,
        #page-sobre.simple-header.navbar ul.menu li ul.box-menu li .checkbox,
        #page-sobre .navbar ul.menu li ul.box-login li .checkbox,
        #page-sobre .navbar ul.menu li ul.box-menu li .checkbox,
        #page-modelos.simple-header.navbar ul.menu li ul.box-login li .checkbox,
        #page-modelos.simple-header.navbar ul.menu li ul.box-menu li .checkbox,
        #page-modelos .navbar ul.menu li ul.box-login li .checkbox,
        #page-modelos .navbar ul.menu li ul.box-menu li .checkbox,
        #page-cidades.simple-header.navbar ul.menu li ul.box-login li .checkbox,
        #page-cidades.simple-header.navbar ul.menu li ul.box-menu li .checkbox,
        #page-cidades .navbar ul.menu li ul.box-login li .checkbox,
        #page-cidades .navbar ul.menu li ul.box-menu li .checkbox {
          color: #939598;
          font-size: 11px;
          margin-top: 5px;
          margin-bottom: 0;
          min-height: auto; }
          #page-home.simple-header.navbar ul.menu li ul.box-login li .checkbox input,
          #page-home.simple-header.navbar ul.menu li ul.box-menu li .checkbox input,
          #page-home .navbar ul.menu li ul.box-login li .checkbox input,
          #page-home .navbar ul.menu li ul.box-menu li .checkbox input,
          #page-sobre.simple-header.navbar ul.menu li ul.box-login li .checkbox input,
          #page-sobre.simple-header.navbar ul.menu li ul.box-menu li .checkbox input,
          #page-sobre .navbar ul.menu li ul.box-login li .checkbox input,
          #page-sobre .navbar ul.menu li ul.box-menu li .checkbox input,
          #page-modelos.simple-header.navbar ul.menu li ul.box-login li .checkbox input,
          #page-modelos.simple-header.navbar ul.menu li ul.box-menu li .checkbox input,
          #page-modelos .navbar ul.menu li ul.box-login li .checkbox input,
          #page-modelos .navbar ul.menu li ul.box-menu li .checkbox input,
          #page-cidades.simple-header.navbar ul.menu li ul.box-login li .checkbox input,
          #page-cidades.simple-header.navbar ul.menu li ul.box-menu li .checkbox input,
          #page-cidades .navbar ul.menu li ul.box-login li .checkbox input,
          #page-cidades .navbar ul.menu li ul.box-menu li .checkbox input {
            margin-top: 2px; }
    #page-home.simple-header.navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-home .navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-sobre.simple-header.navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-sobre .navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-modelos.simple-header.navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-modelos .navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-cidades.simple-header.navbar ul.menu li.dropdown.open .dropdown-toggle,
    #page-cidades .navbar ul.menu li.dropdown.open .dropdown-toggle {
      color: #FFF; }
    #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-home .navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu,
    #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu {
      border-radius: 0;
      margin: 0;
      background-color: #191919; }
      #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-home .navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a,
      #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu li:first-child a {
        padding-top: 20px; }
      #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-home .navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a,
      #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu li:last-child a {
        padding-bottom: 25px; }
      #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-home .navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a,
      #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu li a {
        padding: 10px 30px; }
        #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-home .navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:hover,
        #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu li a:hover {
          background-color: transparent;
          color: #fff; }
        #page-home.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-home .navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-sobre.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-sobre .navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-modelos.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-modelos .navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-cidades.simple-header.navbar ul.menu li.dropdown ul.dropdown-menu li a:active,
        #page-cidades .navbar ul.menu li.dropdown ul.dropdown-menu li a:active {
          background-color: transparent;
          color: #fff; }

.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

#intro {
  margin-top: 60px;
  position: relative; }
  #intro .item {
    background-size: cover;
    background-position: center top; }
    #intro .item .height-fix {
      visibility: hidden;
      opacity: 0;
      max-height: 800px; }
  #intro .chamada {
    position: absolute;
    top: 30%;
    left: 15%; }
    #intro .chamada .btn {
      margin-top: 20px;
      width: 285px;
      border: 1px solid #191919;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #191919; }
      #intro .chamada .btn:hover {
        background-color: #191919;
        color: #fff; }

.enderecos {
  background-color: #f5f5f5;
  padding-top: 50px; }
  .enderecos .middle {
    border-right: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2; }
  .enderecos .right {
    border-right: 1px solid #d2d2d2; }
  .enderecos hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top-color: #d2d2d2; }

#page-sobre {
  background-color: #f5f5f5;
  margin-bottom: 160px; }
  #page-sobre .chamada {
    top: 45%;
    color: #939598;
    font-size: 50px; }
  #page-sobre .sobre {
    margin-top: 60px;
    min-height: 526px;
    background-color: #f5f5f5;
    background-image: url(../images/background_sobre_text.png);
    background-repeat: no-repeat; }
    #page-sobre .sobre .text {
      margin-top: 50px; }

#page-modelos {
  background: #f5f5f5; }
  #page-modelos .cities {
    margin-top: 60px;
    margin-bottom: 40px; }
    #page-modelos .cities h3 {
      margin-top: 40px;
      margin-bottom: 20px; }
    #page-modelos .cities h4 {
      font-weight: bold; }
    #page-modelos .cities .title {
      margin-bottom: 40px; }
    #page-modelos .cities a {
      text-decoration: none;
      color: #191919; }
      #page-modelos .cities a:hover {
        color: #191919; }
    #page-modelos .cities .box {
      border: 1px solid #e1e1e1;
      padding: 50px 0;
      margin-bottom: 15px; }
      #page-modelos .cities .box .marker {
        width: 25%;
        margin-left: 38%;
        font-size: 35px;
        border: 1px solid #e1e1e1;
        border-radius: 50%; }

#page-cidades {
  margin-bottom: 145px;
  background: #f5f5f5; }
  #page-cidades .nav-pills > li + li {
    margin-left: 0px; }
  #page-cidades .title {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 30px; }
  #page-cidades #gender.nav-justified {
    margin: 0 auto 30px auto; }
  #page-cidades #gender li:first-child a {
    margin-right: 30px; }
  #page-cidades #gender li a {
    border-radius: 0;
    border: 1px solid #d2d2d2;
    color: #939598;
    background-color: transparent; }
    #page-cidades #gender li a:hover {
      background-color: transparent;
      color: #58595b; }
  #page-cidades #gender li.active a {
    border: 1px solid #191919;
    background-color: #191919;
    color: #FFF; }
    #page-cidades #gender li.active a:hover {
      border: 1px solid #191919;
      background-color: #191919; }
  #page-cidades .perfil {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px; }
    #page-cidades .perfil .img {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      height: auto;
      max-width: 180px; }
      #page-cidades .perfil .img img {
        min-width: 180px;
        height: auto; }
      #page-cidades .perfil .img .over {
        position: absolute;
        bottom: 0;
        background: #191919;
        padding: 5px 0 5px 10px;
        border-right: solid 10px #191919;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.3s ease-in-out;
        text-transform: capitalize;
        height: 30px;
        max-width: 95%;
        white-space: nowrap;
        overflow: hidden; }
        #page-cidades .perfil .img .over.degrade:before {
          content: "";
          background: url(../images/degrade.png) no-repeat bottom right;
          display: block;
          position: absolute;
          pointer-events: none;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 30px;
          z-index: 15; }
      #page-cidades .perfil .img .overlay {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(25, 25, 25, 0.85);
        opacity: 0;
        -webkit-transition: opacity .25s ease;
        -moz-transition: opacity .25s ease;
        color: #fff; }
        #page-cidades .perfil .img .overlay:hover {
          opacity: 1;
          z-index: 16; }
        #page-cidades .perfil .img .overlay .overlay-content {
          font-weight: 600; }
    #page-cidades .perfil .text {
      border-top: 5px solid #191919;
      border-right: 1px solid #191919;
      border-left: 1px solid #191919;
      border-bottom: 1px solid #191919;
      padding: 10px 20px;
      color: #191919;
      font-size: 12px;
      min-height: 265px;
      position: relative; }
      #page-cidades .perfil .text strong {
        color: #191919; }

footer {
  background-color: #f5f5f5;
  padding-bottom: 50px; }
  footer hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top-color: #d2d2d2; }
  footer.sticky {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 160px; }

/* Mobile */
@media (max-width: 767px) {
  #page-home .navbar,
  #page-sobre .navbar,
  #page-modelos .navbar,
  #page-cidades .navbar {
    height: 60px; }
    #page-home .navbar img.logo,
    #page-sobre .navbar img.logo,
    #page-modelos .navbar img.logo,
    #page-cidades .navbar img.logo {
      float: left; }
    #page-home .navbar ul.menu,
    #page-sobre .navbar ul.menu,
    #page-modelos .navbar ul.menu,
    #page-cidades .navbar ul.menu {
      text-align: right;
      margin-right: -15px; }
      #page-home .navbar ul.menu li a.button-box-menu,
      #page-sobre .navbar ul.menu li a.button-box-menu,
      #page-modelos .navbar ul.menu li a.button-box-menu,
      #page-cidades .navbar ul.menu li a.button-box-menu {
        font-size: 20px;
        color: #58595b;
        padding-top: 0;
        padding-bottom: 0; }
      #page-home .navbar ul.menu li a.button-box-login,
      #page-sobre .navbar ul.menu li a.button-box-login,
      #page-modelos .navbar ul.menu li a.button-box-login,
      #page-cidades .navbar ul.menu li a.button-box-login {
        padding-left: 15px;
        padding-right: 15px; }
        #page-home .navbar ul.menu li a.button-box-login .box .circle,
        #page-sobre .navbar ul.menu li a.button-box-login .box .circle,
        #page-modelos .navbar ul.menu li a.button-box-login .box .circle,
        #page-cidades .navbar ul.menu li a.button-box-login .box .circle {
          margin-left: 20px; }
      #page-home .navbar ul.menu li ul.box-menu,
      #page-sobre .navbar ul.menu li ul.box-menu,
      #page-modelos .navbar ul.menu li ul.box-menu,
      #page-cidades .navbar ul.menu li ul.box-menu {
        display: none;
        top: 46px;
        right: -80px; }
        #page-home .navbar ul.menu li ul.box-menu li,
        #page-sobre .navbar ul.menu li ul.box-menu li,
        #page-modelos .navbar ul.menu li ul.box-menu li,
        #page-cidades .navbar ul.menu li ul.box-menu li {
          text-align: center; }
      #page-home .navbar ul.menu li ul.box-login,
      #page-sobre .navbar ul.menu li ul.box-login,
      #page-modelos .navbar ul.menu li ul.box-login,
      #page-cidades .navbar ul.menu li ul.box-login {
        width: 100%; }
  #intro {
    height: auto; }
    #intro .chamada {
      top: 78%;
      left: 50%;
      margin-left: -142px; }
  .enderecos .middle,
  .enderecos .right {
    border: 0; }
  #page-sobre #intro {
    height: auto; }
    #page-sobre #intro .item.one {
      background-image: url(../images/background_sobre_mobile.jpg) !important; }
    #page-sobre #intro .chamada {
      width: 100%;
      text-align: center;
      top: 65%;
      left: auto;
      margin: auto; }
  #page-sobre .sobre {
    background-image: none; }
  #page-modelos .cities {
    margin-bottom: 145px; }
    #page-modelos .cities .box {
      padding: 80px 0; }
      #page-modelos .cities .box .marker {
        width: 15%;
        margin-left: 42%; }
  #page-cidades #gender li:first-child a {
    margin-right: 0; } }
